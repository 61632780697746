import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

// Хуки
import useAdvanceTable from 'hooks/useAdvanceTable';

// Провайдеры
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

// Компоненты
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { Card } from 'react-bootstrap';

import { ITransaction } from './types';

type TransactionsTablePropsTypes = {
  pagination: { page: number; limit: number; countAll?: number };
  transactions?: ITransaction[];
  handlePageChange: (page: number) => void;
};

const TransactionsTable = (props: TransactionsTablePropsTypes) => {
  const { transactions, pagination, handlePageChange } = props;
  const [preparedData, setPreparedData] = useState<ITransaction[]>([]);

  // Моковые данные для отображения имен отправителя и получателя
  const accountIdToNameMap = {
    '1234654765867': 'Иванов Иван',
    '8234654765867': 'Петров Пётр'
    // Добавьте другие соответствия по необходимости
  };

  const columns = [
    {
      accessorKey: 'trxId',
      header: 'ID Транзакции',
      meta: {
        headerProps: { className: 'text-900 ps-3 py-2' },
        cellProps: { className: 'ps-4 py-2' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => (
        <Link
          to={`/transactions/${original.trxId}`}
          className="text-truncate mb-0"
        >
          {original.trxId}
        </Link>
      )
    },
    {
      accessorKey: 'accountOut',
      header: 'Отправитель',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { accountOut } = original;
        // @ts-ignore
        const senderName = accountIdToNameMap[accountOut] || accountOut;
        return (
          <div style={{ maxWidth: '150px' }} className="text-truncate">
            {senderName}
          </div>
        );
      }
    },
    {
      accessorKey: 'accountIn',
      header: 'Получатель',
      meta: {
        headerProps: { className: 'text-900 ps-4' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { accountIn } = original;
        // @ts-ignore
        const receiverName = accountIn || accountIdToNameMap[accountIn];
        return (
          <div style={{ maxWidth: '150px' }} className="text-truncate">
            {receiverName}
          </div>
        );
      }
    },
    {
      accessorKey: 'amount',
      header: 'Сумма',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { amount } = original;
        return <span>{amount}</span>;
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { currency } = original;
        return <span>{currency}</span>;
      }
    },
    {
      accessorKey: 'fee',
      header: 'Комиссия',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { fee } = original;
        return <span>{fee}%</span>;
      }
    },
    {
      accessorKey: 'start_date',
      header: 'Время начала операции',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return dayjs(original.start_date).format('DD.MM.YYYY HH:mm:ss');
      }
    },
    {
      accessorKey: 'update_date',
      header: 'Время обновления',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.update_date === '0001-01-01T00:00:00Z'
          ? 'Не обновлено'
          : dayjs(original.update_date).format('DD.MM.YYYY HH:mm:ss');
      }
    },
    {
      accessorKey: 'kind',
      header: 'Статус',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { kind } = original;
        return <span>{kind}</span>;
      }
    },
    {
      accessorKey: 'operationType',
      header: 'Тип операции',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: () => {
        return <span>Не доступно</span>; // Здесь вы можете отобразить тип операции, если данные доступны
      }
    },
    {
      accessorKey: 'description',
      header: 'Комментарий',
      meta: {
        headerProps: { className: 'ps-4 text-900' },
        cellProps: { className: 'ps-4' }
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { description } = original;
        return (
          <p className="text-truncate mb-0" style={{ maxWidth: '150px' }}>
            {description ? description : 'Нет комментариев'}
          </p>
        );
      }
    }
  ];

  useEffect(() => {
    if (!transactions?.length) return;
    setPreparedData(transactions);
  }, [transactions]);

  const table = useAdvanceTable({
    data: preparedData,
    columns,
    sortable: true,
    pagination: true,
    perPage: pagination?.limit,
    page: pagination?.page,
    rowCount: pagination?.countAll,
    setPage: (page: number) => handlePageChange(++page),
    previousPage: (page: number) => handlePageChange(++page),
    nextPage: (page: number) => handlePageChange(++page + 1)
  } as any);

  return (
    <div className="w-100">
      <AdvanceTableProvider {...table}>
        <Card.Body className="p-0">
          <AdvanceTable
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination />
        </Card.Footer>
      </AdvanceTableProvider>
    </div>
  );
};

export default TransactionsTable;
