import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Table
} from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import { MultiValue } from 'react-select';
import ApiService from '../../helpers/apiService';
import { v4 as uuidv4 } from 'uuid';
import '../sendmessage/Sendmessage.css'; // Импорт стилей

interface Recipient {
  id?: string;
  value: string;
  label: string;
}

interface TemplateParameter {
  name: string;
  param: string;
}

const MessageSender: React.FC = () => {
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [sendType, setSendType] = useState<string>('');
  const [template, setTemplate] = useState<string>('');
  const [parameters, setParameters] = useState<string[]>([]);
  const [sender, setSender] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [templates, setTemplates] = useState<any[]>([]);
  const [availableParameters, setAvailableParameters] = useState<
    TemplateParameter[]
  >([]);
  const [availableSenders] = useState<string[]>([
    'Push',
    'Notyfi',
    'SMS (TeslaPay)',
    'SMS (Manex)',
    'Email (TeslaPay)',
    'Email (Manex)'
  ]);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    const requestId = uuidv4();
    try {
      const data = await ApiService.get<{ data: any[] }>(
        '/notify/v1/template',
        {
          headers: { requestId }
        }
      );
      setTemplates(data.data);
    } catch (error) {
      console.error('Ошибка при загрузке шаблонов:', error);
    }
  };

  const handleTemplateChange = (eventKey: string | null) => {
    if (eventKey) {
      const selectedTemplate = templates.find(tmpl => tmpl.id === eventKey);
      setTemplate(eventKey);

      if (selectedTemplate && selectedTemplate.params) {
        const params = Object.entries(selectedTemplate.params).map(
          ([param, name]) => ({
            name: name as string,
            param
          })
        );
        setAvailableParameters(params);
        setMessage(selectedTemplate.content);
      }
    }
  };

  const handleParameterToggle = (paramName: string) => {
    if (parameters.includes(paramName)) {
      setParameters(parameters.filter(param => param !== paramName));
      setMessage(msg => msg.replace(new RegExp(`%%${paramName}%%`, 'g'), ''));
    } else {
      setParameters([...parameters, paramName]);
      setMessage(msg => `${msg} %%${paramName}%%`);
    }
  };

  const handleSendTypeChange = (eventKey: string | null) => {
    if (eventKey) {
      setSendType(eventKey);
    }
  };

  const handleSenderChange = (eventKey: string | null) => {
    if (eventKey) setSender(eventKey);
  };

  const handleOpenUserModal = () => {
    setShowUserModal(true);
    fetchUsers();
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
  };

  const fetchUsers = async () => {
    const requestId = uuidv4();
    try {
      const data = await ApiService.get<{ data: any[] }>('/profile/v1/list', {
        headers: { requestId }
      });
      setUserList(data.data);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = userList.filter(
    user =>
      (user.fullName &&
        user.fullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.phone && user.phone.includes(searchQuery)) ||
      (user.email &&
        user.email.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleSelectUser = (user: any) => {
    const phoneNumber = user.phone;
    const userId = user.id;
    if (
      phoneNumber &&
      !recipients.some(recipient => recipient.value === phoneNumber)
    ) {
      setRecipients([
        ...recipients,
        { id: userId, value: phoneNumber, label: phoneNumber }
      ]);
    }
    handleCloseUserModal();
  };

  const getUserIdByPhone = async (
    phoneNumber: string
  ): Promise<string | null> => {
    try {
      const requestId = uuidv4();
      const response = await ApiService.get<{ data: { id: string } }>(
        `/profile/v1/user_by_phone/${phoneNumber}`,
        {
          headers: { requestId }
        }
      );
      return response.data.id || null;
    } catch (error) {
      console.error('Ошибка при получении user_id по номеру телефона:', error);
      return null;
    }
  };

  const sendMessage = async () => {
    const requestId = uuidv4();
    if (!sendType || !sender) {
      alert('Пожалуйста, выберите вид отправки и отправителя');
      return;
    }

    if (recipients.length === 0) {
      alert('Пожалуйста, выберите получателей');
      return;
    }

    try {
      for (const recipient of recipients) {
        let userId: any = recipient.id;

        // Если userId отсутствует, пытаемся получить его по номеру телефона
        if (!userId) {
          userId = await getUserIdByPhone(recipient.value);
          if (!userId) {
            alert(`Не удалось найти пользователя с номером ${recipient.value}`);
            continue;
          }
        }

        if (sendType === 'Notyfi') {
          // Используем метод /notify/v1/send
          const payload = {
            user_id: userId,
            title: 'Новое сообщение',
            message,
            link: 'https://gsmsoft.eu'
          };

          await ApiService.post('/notify/v1/send', payload, {
            headers: { requestId }
          });
        } else {
          // Используем метод /notify/v1/send_template
          // Собираем данные параметров
          const templateData: { [key: string]: string } = {};
          availableParameters.forEach(param => {
            // Здесь необходимо получить реальные значения параметров от пользователя
            templateData[param.param] = 'значение'; // Замените на реальные значения параметров
          });

          const payload = {
            user_id: userId,
            template_name: template,
            data: templateData
          };

          await ApiService.post('/notify/v1/send_template', payload, {
            headers: { requestId }
          });
        }
      }

      alert('Сообщение отправлено успешно');
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      alert('Произошла ошибка при отправке сообщения');
    }
  };

  return (
    <div className="message-sender">
      <Form className="form-layout">
        {/* Поле получателя */}
        <Form.Group className="form-row" controlId="recipients">
          <Form.Label className="form-label">Получатель:</Form.Label>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1' }}>
              <CreatableSelect
                isMulti
                onChange={(selectedOptions: MultiValue<Recipient>) => {
                  setRecipients(selectedOptions ? [...selectedOptions] : []);
                }}
                value={recipients}
                placeholder="Введите номер телефона или выберите из списка"
                formatCreateLabel={(inputValue: string) =>
                  `Добавить "${inputValue}"`
                }
                isValidNewOption={(inputValue: string): boolean => {
                  return !!inputValue && /^\+?\d+$/.test(inputValue);
                }}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Button variant="outline-secondary" onClick={handleOpenUserModal}>
                <FaUserPlus />
              </Button>
            </div>
          </div>
        </Form.Group>

        {/* Вид отправки */}
        <Form.Group className="form-row" controlId="sendType">
          <Form.Label className="form-label">Вид отправки:</Form.Label>
          <DropdownButton
            className="dropdown-button"
            title={sendType || 'Выберите тип'}
            onSelect={handleSendTypeChange}
          >
            <Dropdown.Item eventKey="Push">Push</Dropdown.Item>
            <Dropdown.Item eventKey="Notyfi">Notyfi</Dropdown.Item>
            <Dropdown.Item eventKey="SMS">SMS</Dropdown.Item>
            <Dropdown.Item eventKey="Email">Email</Dropdown.Item>
            <Dropdown.Item eventKey="Telegram">Telegram</Dropdown.Item>
            <Dropdown.Item eventKey="WhatsApp">WhatsApp</Dropdown.Item>
          </DropdownButton>
        </Form.Group>

        {/* Шаблон */}
        <Form.Group className="form-row" controlId="template">
          <Form.Label className="form-label">Шаблон:</Form.Label>
          <DropdownButton
            className="dropdown-button"
            title={template || 'Выберите шаблон'}
            onSelect={handleTemplateChange}
          >
            {templates.map((tmpl: any) => (
              <Dropdown.Item key={tmpl.id} eventKey={tmpl.id}>
                {tmpl.id}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Form.Group>

        {/* Параметры */}
        <Form.Group className="form-row" controlId="parameters">
          <Form.Label className="form-label">Параметры:</Form.Label>
          <DropdownButton
            className="dropdown-button"
            title="Выберите параметры"
          >
            {availableParameters.map(param => (
              <Dropdown.Item
                key={param.param}
                onClick={() => handleParameterToggle(param.param)}
              >
                {param.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Form.Group>

        {/* Отправитель */}
        <Form.Group className="form-row" controlId="sender">
          <Form.Label className="form-label">Отправитель:</Form.Label>
          <DropdownButton
            className="dropdown-button"
            title={sender || 'Выберите отправителя'}
            onSelect={handleSenderChange}
          >
            {availableSenders.map(s => (
              <Dropdown.Item key={s} eventKey={s}>
                {s}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Form.Group>

        {/* Сообщение */}
        <Form.Group controlId="message">
          <Form.Label>Сообщение:</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" onClick={sendMessage}>
          Отправить
        </Button>
      </Form>

      {/* Модальное окно выбора пользователя */}
      <Modal show={showUserModal} onHide={handleCloseUserModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Выберите пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            placeholder="Поиск пользователя"
            value={searchQuery}
            onChange={handleSearchChange}
            className="mb-3"
          />
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>ФИО</th>
                <th>Телефон</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr
                  key={user.id}
                  onClick={() => handleSelectUser(user)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{user.fullName || user.name}</td>
                  <td>{user.phone}</td>
                  <td>{user.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserModal}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MessageSender;
