import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

class ApiService {
  private axiosInstance: AxiosInstance;
  private isRefreshing = false;
  private failedQueue: any[] = [];

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Interceptors for requests
    this.axiosInstance.interceptors.request.use(
      config => {
        const token = localStorage.getItem('token');
        const isProfileRequest = config.url?.startsWith('/profile');

        // Add Authorization token if available
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
          if (isProfileRequest) {
            console.log(
              'Authorization token добавлен для запроса профиля:',
              token
            );
          }
        } else if (isProfileRequest) {
          console.error('Токен отсутствует в localStorage для запроса профиля');
        }

        // Add requestId to every request
        config.headers['requestId'] = uuidv4();

        return config;
      },
      error => Promise.reject(error)
    );

    // Interceptors for responses
    this.axiosInstance.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config;

        if (
          error.response &&
          error.response.status === 403 &&
          !originalRequest._retry
        ) {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            originalRequest._retry = true;

            const refreshToken = localStorage.getItem('refreshToken');

            return this.refreshToken(refreshToken)
              .then(newToken => {
                localStorage.setItem('token', newToken);
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                this.isRefreshing = false;
                this.processQueue(null, newToken);
                return this.axiosInstance(originalRequest);
              })
              .catch(err => {
                this.processQueue(err, null);
                return Promise.reject(err);
              });
          }

          return new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              return this.axiosInstance(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        if (error.response) {
          const { status, data } = error.response;
          const errorMessages: { [key: number]: string } = {
            400: 'Некорректный запрос',
            401: 'Ошибка авторизации',
            403: 'Доступ запрещен',
            404: 'Ресурс не найден',
            500: 'Внутренняя ошибка сервера'
          };

          const errorMessage = errorMessages[status] || 'Произошла ошибка';
          console.error(`${errorMessage}:`, data);
        } else {
          console.error('Ошибка без ответа сервера:', error.message);
        }

        return Promise.reject(error);
      }
    );
  }
  private handleRefreshTokenError() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/authentication/simple/login'; // Redirect to login
  }

  // Refresh token method
  private refreshToken(refreshToken: string | null): Promise<string> {
    const requestId = uuidv4();
    return this.axiosInstance
      .post(
        '/auth/v1/refresh',
        { refresh_token: refreshToken, refreshToken },
        {
          headers: {
            requestId
          }
        }
      )
      .then(response => response.data.data.token)
      .catch(error => {
        console.error(
          'Failed to refresh token:',
          error.response?.data || error.message
        );
        this.handleRefreshTokenError();
        throw error;
      });
  }

  private processQueue(error: any, token: string | null = null) {
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedQueue = [];
  }

  // GET method
  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.get(url, config).then(response => response.data);
  }

  // POST method
  public post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.axiosInstance
      .post(url, data, config)
      .then(response => response.data);
  }

  // Получение транзакций с requestId в заголовке
  public fetchTransactions<ITransactionResponse>(
    filters: any
  ): Promise<ITransactionResponse> {
    if (!filters.trxId) {
      delete filters.trxId;
    }

    return this.axiosInstance
      .get<ITransactionResponse>('/operations/v1/get/', {
        headers: {
          requestId: uuidv4()
        },
        params: filters
      })
      .then(response => response.data);
  }

  // Метод поиска аккаунтов
  public searchAccounts(params: {
    search?: string;
    currency?: string;
    dateFrom?: string;
    dateTo?: string;
    page: number;
    limit: number;
  }): Promise<any> {
    return this.axiosInstance
      .get('/account/v1/list/', {
        params: {
          search: params.search,
          currency: params.currency,
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
          page: params.page,
          limit: params.limit
        }
      })
      .then(response => response.data);
  }

  // Получение транзакций для конкретного аккаунта
  public getTransactions(
    accountId: string,
    params: { page: number; limit: number }
  ): Promise<any> {
    return this.axiosInstance
      .get(`/account/v1/${accountId}/transactions`, { params })
      .then(response => response.data);
  }

  // PATCH запрос для сброса пароля
  public resetPassword(requestId: string): Promise<any> {
    return this.axiosInstance
      .patch(
        '/profile/v1/resetPassword',
        {}, // Пустое тело запроса
        {
          headers: {
            requestId // Только requestId, Authorization добавляется автоматически
          }
        }
      )
      .then(response => {
        console.log('Password reset success:', response.data);
        return response.data;
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 400) {
            console.error('Invalid request:', error.response.data);
          } else if (error.response.status === 401) {
            console.error('Authorization error:', error.response.data);
          } else {
            console.error('An error occurred:', error.response.data);
          }
        }
        throw error;
      });
  }
  getOperators<T>(requestId: string): Promise<T> {
    return this.axiosInstance
      .get('/profile/v1/list', {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}` // Add Authorization header
        }
      })
      .then(response => response.data);
  }

  // POST request to add operator
  // POST request to add operator
  addOperator<T>(operatorData: any, requestId: string): Promise<T> {
    // Ensure you send the full expected operator data in the request
    const payload = {
      data: {
        id: operatorData.id || uuidv4(), // You can generate a new UUID or handle it dynamically
        name: operatorData.name, // Make sure name is not empty
        phone: operatorData.phone || '+71234567890', // Example phone, replace with actual data
        type: operatorData.profileType || '1', // Default type
        status: operatorData.status || 'active', // Example status, can be 'blocked' or 'active'
        email: operatorData.email, // Make sure email is valid
        roles: operatorData.roles || [], // Array of roles
        group: operatorData.group,
        data: [operatorData.group] // Any additional data
      }
    };

    return this.axiosInstance
      .post('/profile/v1/add', payload, {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.data);
  }

  // PATCH request to update operator
  editOperator<T>(operatorData: any, requestId: string): Promise<T> {
    console.log(operatorData);
    const payload = {
      data: {
        id: operatorData.id, // You can generate a new UUID or handle it dynamically
        name: operatorData.name, // Make sure name is not empty
        phone: operatorData.phone || '+71234567890', // Example phone, replace with actual data
        type: operatorData.profileType || '1', // Default type
        status: operatorData.enabled ? 'active' : 'blocked', // Example status, can be 'blocked' or 'active'
        email: operatorData.email, // Make sure email is valid
        roles: operatorData.roles || [], // Array of roles
        group: operatorData.group,
        data: [operatorData.group] // Any additional data
      }
    };

    return this.axiosInstance
      .patch('/profile/v1/edit', payload, {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.data);
  }

  // DELETE request to delete operator
  deleteOperator<T>(operatorId: string, requestId: string): Promise<T> {
    return this.axiosInstance
      .delete('/profile/v1/delete', {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: {
          id: operatorId // Send operator ID in the body
        }
      })
      .then(response => response.data);
  }
  // GET list of roles
  public getListRoles(requestId: string): Promise<any> {
    return this.axiosInstance
      .get('/profile/v1/list_roles', {
        headers: { requestId }
      })
      .then(response => response.data);
  }

  // POST new role
  public addRole<T>(data: any, requestId: string): Promise<T> {
    return this.axiosInstance
      .post('/profile/v1/add_role', data, {
        headers: { requestId }
      })
      .then(response => response.data);
  }

  // PUT edit existing role
  public editRole<T>(data: any, requestId: string): Promise<T> {
    return this.axiosInstance
      .put('/profile/v1/edit_role', data, {
        headers: { requestId }
      })
      .then(response => response.data);
  }

  // DELETE role
  public deleteRole<T>(idRole: string, requestId: string): Promise<T> {
    return this.axiosInstance
      .delete('/profile/v1/delete_role', {
        data: { idRole },
        headers: { requestId }
      })
      .then(response => response.data);
  }

  public getListAccess(requestId: string): Promise<any> {
    return this.axiosInstance
      .get('/profile/v1/list_access', {
        headers: { requestId }
      })
      .then(response => response.data);
  }
  public getLimits<T>(
    params: {
      codes?: string;
      ids?: string;
      types?: string;
      objects?: string;
      page?: number;
      size?: number;
    },
    requestId: string
  ): Promise<T> {
    return this.axiosInstance
      .get('/support/v1/limits', {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params
      })
      .then(response => response.data)
      .catch(error => {
        console.error(
          'Ошибка при получении лимитов:',
          error.response?.data || error.message
        );
        throw error;
      });
  }

  public addLimit<T>(limitData: any, requestId: string): Promise<T> {
    return this.axiosInstance
      .post('/support/v1/limits', limitData, {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.data);
  }

  public updateLimit<T>(limitData: any, requestId: string): Promise<T> {
    return this.axiosInstance
      .put('/support/v1/limits', limitData, {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.data);
  }

  public deleteLimit<T>(code: string, requestId: string): Promise<T> {
    return this.axiosInstance
      .delete('/support/v1/limits', {
        headers: {
          requestId,
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        data: {
          code
        }
      })
      .then(response => response.data);
  }
  public blockUser(userId: string, comment: string): Promise<any> {
    return this.axiosInstance.put(
      '/user/v1/block',
      { userId, comment }, // передаем userId и comment
      {
        headers: {
          requestId: uuidv4(),
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  }

  public unblockUser(userId: string, comment: string): Promise<any> {
    return this.axiosInstance.put(
      '/user/v1/unblock',
      { userId, comment }, // передаем userId и comment
      {
        headers: {
          requestId: uuidv4(),
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  }
  public getTemplates(): Promise<any> {
    return this.axiosInstance
      .get('/notify/v1/template', {
        headers: {
          requestId: uuidv4()
        }
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Ошибка при получении шаблонов:', error);
        throw error;
      });
  }

  // Method to add a template
  public addTemplate(templateData: any): Promise<any> {
    return this.axiosInstance
      .post('/notify/v1/template', templateData, {
        headers: {
          requestId: uuidv4()
        }
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Ошибка при добавлении шаблона:', error);
        throw error;
      });
  }

  // Method to update a template
  public updateTemplate(templateData: any): Promise<any> {
    return this.axiosInstance
      .put('/notify/v1/template', templateData, {
        headers: {
          requestId: uuidv4()
        }
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Ошибка при обновлении шаблона:', error);
        throw error;
      });
  }

  // Method to delete a template
  public deleteTemplate(templateId: string): Promise<any> {
    return this.axiosInstance
      .delete('/notify/v1/template', {
        headers: {
          requestId: uuidv4(),
          templateId
        }
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Ошибка при удалении шаблона:', error);
        throw error;
      });
  }
}

export default new ApiService();
