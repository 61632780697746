import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import ApiService from '../../helpers/apiService';
import Select from 'react-select';

interface Template {
  id: string;
  mnemonic: string;
  parameters: string[];
  message: string;
}

const parametersList = [
  { label: 'Наименование пользователя', value: 'name' },
  { label: 'Сумма списания', value: 'amount' },
  { label: 'Комиссия', value: 'fee' },
  { label: 'Номер транзакции', value: 'trx_num' },
  { label: 'Лимит', value: 'limit' },
  { label: 'Остаток по лимиту', value: 'limit_remain' },
  { label: 'Причина блокировки', value: 'reason' },
  { label: 'Срок', value: 'duration' },
  { label: 'Отправитель', value: 'send_tmp' }
];

const getParamsObject = (parameters: string[]) => {
  const paramsObject: { [key: string]: string } = {};
  parameters.forEach(param => {
    const paramInfo = parametersList.find(p => p.value === param);
    if (paramInfo) {
      paramsObject[param] = paramInfo.label;
    } else {
      paramsObject[param] = param;
    }
  });
  return paramsObject;
};

const Templates: React.FC = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const [modalType, setModalType] = useState<'add' | 'edit'>('add');

  const [mnemonic, setMnemonic] = useState<string>('');
  const [selectedParameters, setSelectedParameters] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const data = await ApiService.getTemplates();
      const templates = (data.data || []).map((template: any) => ({
        id: template.id,
        mnemonic: template.mnemonic || template.id, // Корректное преобразование
        parameters: Object.keys(template.params || {}),
        message: template.content
      }));
      setTemplates(templates);
    } catch (error) {
      console.error('Ошибка при загрузке шаблонов:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (currentTemplate) {
      setMnemonic(currentTemplate.mnemonic);
      setSelectedParameters(currentTemplate.parameters || []);
      setMessage(currentTemplate.message);
    } else {
      setMnemonic('');
      setSelectedParameters([]);
      setMessage('');
    }
  }, [currentTemplate]);

  const handleAddTemplate = () => {
    setModalType('add');
    setCurrentTemplate(null);
    setShowModal(true);
  };

  const handleEditTemplate = (template: Template) => {
    setModalType('edit');
    setCurrentTemplate(template);
    setShowModal(true);
  };

  const handleDeleteTemplate = async (id: string) => {
    try {
      await ApiService.deleteTemplate(id);
      setTemplates(templates.filter(template => template.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении шаблона:', error);
    }
  };

  const handleParameterChange = (selectedOptions: any) => {
    const newSelectedParameters = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];

    const newlySelectedParams = newSelectedParameters.filter(
      (param: string) => !selectedParameters.includes(param)
    );

    let updatedMessage = message;
    newlySelectedParams.forEach((param: string) => {
      if (!updatedMessage.includes(`%%${param}%%`)) {
        updatedMessage += ` %%${param}%%`;
      }
    });

    setMessage(updatedMessage);
    setSelectedParameters(newSelectedParameters);
  };

  const handleModalSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newTemplate: Template = {
      id: mnemonic, // Use the new mnemonic as the ID
      mnemonic: mnemonic,
      parameters: selectedParameters,
      message: message
    };

    const paramsObject = getParamsObject(newTemplate.parameters);

    try {
      if (modalType === 'add') {
        // Existing add logic
      } else {
        if (currentTemplate?.id !== mnemonic) {
          // Mnemonic has changed, delete old and add new
          // @ts-ignore
          await ApiService.deleteTemplate(currentTemplate.id);
          await ApiService.addTemplate({
            id: newTemplate.id,
            mnemonic: mnemonic,
            content: newTemplate.message,
            params: paramsObject
          });
        } else {
          // Mnemonic hasn't changed, proceed with update
          await ApiService.updateTemplate({
            id: currentTemplate?.id,
            mnemonic: mnemonic,
            content: newTemplate.message,
            params: paramsObject
          });
        }
        // Update the templates list
        fetchTemplates();
      }
      setShowModal(false);
    } catch (error) {
      console.error('Ошибка при сохранении шаблона:', error);
    }
  };

  return (
    <div className="Templates-page">
      <div className="mb-3 d-flex justify-content-end">
        <Button variant="primary" onClick={handleAddTemplate}>
          +
        </Button>
      </div>

      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px'
        }}
      >
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Мнемоника</th>
                <th>Параметры</th>
                <th>Сообщение</th>
                <th>Управление</th>
              </tr>
            </thead>
            <tbody>
              {templates.map(template => (
                <tr key={template.id}>
                  <td>{template.mnemonic}</td>
                  <td>{(template.parameters || []).join(', ')}</td>
                  <td>{template.message}</td>
                  <td>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => handleEditTemplate(template)}
                    >
                      ✏️
                    </Button>{' '}
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleDeleteTemplate(template.id)}
                    >
                      🗑️
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Form onSubmit={handleModalSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalType === 'add' ? 'Добавить шаблон' : 'Редактировать шаблон'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="mnemonic">
              <Form.Label>Мнемоника</Form.Label>
              <Form.Control
                type="text"
                name="mnemonic"
                value={mnemonic}
                onChange={e => setMnemonic(e.target.value)}
                pattern="^[a-zA-Z0-9_]+$"
                placeholder="Только латинские буквы и цифры"
                required
              />
            </Form.Group>
            <Form.Group controlId="parameters">
              <Form.Label>Параметры</Form.Label>
              <Select
                isMulti
                value={selectedParameters.map(value => {
                  const param = parametersList.find(p => p.value === value);
                  return { value: value, label: param ? param.label : value };
                })}
                onChange={handleParameterChange}
                options={parametersList}
                closeMenuOnSelect={false}
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>Сообщение</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="Привет %%name%%, ваш баланс пополнен на %%amount%%"
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Отмена
            </Button>
            <Button variant="primary" type="submit">
              Сохранить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Templates;
